import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import startCase from "lodash/startCase";
import JsBarcode from "jsbarcode";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import {
  vendorPickupDetail,
  maskPhoneV2,
  limitLengthName,
  badWordsMasking
} from "@/app/ui/modules/receipt-v2/module";
import { ellipsisString } from "@/app/infrastructures/misc/Utils";

export const createRoundRect = (doc: jsPDF | any, text: string, x: number, y: number) => {
  doc.setFontSize(9);
  doc.setDrawColor(0);
  doc.setFillColor(0, 0, 0);

  doc.roundedRect(x, y, doc.getTextDimensions(text).w + 2.3, doc.getTextDimensions(text).h + 1.9, 1, 1, "F");

  doc.setTextColor("#FFFFFF");
  doc.setFont("Montserrat", "bold");
  doc.text(text, x + 1, y + 3.6);
  return {
    w: doc.getTextDimensions(text).w + 2.3,
    h: doc.getTextDimensions(text).h + 1.9
  };
};

export const DeliverySection = async (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function,
  language = "id"
) => {

  const leftMargin = params.startX + 5;
  const rightEndX = params.startX + 77;

  // PENGIRIM
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(6);

  const senderPositionY = params.startY + 40 + 4;
  params.doc.setFont("Montserrat", "bold");

  const sttSenderNameData = limitLengthName(params.data.sttSenderName, 60);
  const sttSenderText =
    startCase(translate("receipt.shipper")).toUpperCase() +
    ": " +
    sttSenderNameData.toUpperCase() +
    " " +
    maskPhoneV2(params.data.sttSenderPhone);

  const sttSenderTextSplitted = params.doc.splitTextToSize(sttSenderText, 70);

  params.doc.text(sttSenderTextSplitted, leftMargin, senderPositionY);

  // PENERIMA
  const sttSenderTextSplittedHeight = params.doc.getTextDimensions(
    sttSenderTextSplitted
  ).h;
  const sttRecipientName = limitLengthName(params.data.sttRecipientName, 85);

  const recipientPositionY = senderPositionY + sttSenderTextSplittedHeight + 1;
  params.doc.setFont("Montserrat", "bold");

  const sttRecipientText =
    startCase(translate("receipt.receiver")).toUpperCase() +
    ": " +
    sttRecipientName.toUpperCase();

  const sttRecipientTextSplitted = params.doc.splitTextToSize(
    sttRecipientText,
    70
  );
  params.doc.text(sttRecipientTextSplitted, leftMargin, recipientPositionY);
  params.doc.setFont("Montserrat", "normal");

  // PENERIMA PHONE
  let phoneRecipientPositionY =
    recipientPositionY +
    0.5 +
    params.doc.getTextDimensions(sttRecipientTextSplitted).h;

  params.doc.setFont("Montserrat", "bold");

  const phoneAndRecipientAddress = params.doc.splitTextToSize(
    ellipsisString(
      params.data.sttRecipientPhone + " " + params.data.sttRecipientAddress.toUpperCase(),
      200 + params.data.sttRecipientPhone.length
    ),
    70
  );

  params.doc.text(
    phoneAndRecipientAddress,
    leftMargin,
    phoneRecipientPositionY
  );

  if (phoneAndRecipientAddress.length > 3) {
    phoneRecipientPositionY += 1.5;
  }

  // DESTINATION DISTRICT NAME
  const districtPositionY = phoneRecipientPositionY + 13.5;

  params.doc.setFontSize(8);
  params.doc.setFont("Montserrat", "bold");

  const splitedSTTDestinationDistrictName = params.doc.splitTextToSize(
    params.data.sttDestinationDistrictName.toUpperCase(),
    65
  );

  params.doc.text(
    splitedSTTDestinationDistrictName,
    leftMargin,
    districtPositionY
  );

  // FLAG STT
  let sttPositionX = leftMargin;
  const sttPositionY = districtPositionY + 6;
  params.data.sttFlag.forEach((item: string) => {
    const rect = createRoundRect(params.doc, item, sttPositionX, sttPositionY);
    sttPositionX += rect.w + 2;
  });

  // COMMODITY

  const sttCommodityNamelanguage =
    language === "en"
      ? params.data.sttCommodityDescription
      : params.data.sttCommodityName;

  const sttCommodityNameString =
    sttCommodityNamelanguage.length > 30
      ? `${sttCommodityNamelanguage.substring(0, 30)}...`
      : sttCommodityNamelanguage;

  const sttCommodityName = params.doc.splitTextToSize(
    sttCommodityNameString,
    50
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(6);
  params.doc.text(
    sttCommodityName,
    rightEndX - 1,
    sttPositionY + 2,
    null,
    null,
    "right"
  );

  // AWB Vendor - static position
  const awbPositionY = params.startY + 84;
  const barcodePositionY = awbPositionY + 1;
  const sttVendorRefferenceNo = params.data.sttVendorRefferenceNo;
  if (sttVendorRefferenceNo !== "") {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setFontSize(9);
    params.doc.text(sttVendorRefferenceNo, leftMargin, awbPositionY);

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, sttVendorRefferenceNo, {
      displayValue: false,
      margin: 0
    });
    const jpegUrl = canvas.toDataURL("image/jpeg");
    params.doc.addImage(
      jpegUrl,
      "PNG",
      leftMargin + 2,
      barcodePositionY,
      params.doc.getTextDimensions(sttVendorRefferenceNo).w,
      5,
      uuid(),
      "FAST",
      0
    );
  }

  if (params.data.isPriorityTier) {
    const priorityIcon = await import(
      `@/app/ui/assets/images/logo-priority-print.png`
    );

    params.doc.addImage(
      priorityIcon.default,
      "PNG",
      rightEndX - 7,
      barcodePositionY,
      5,
      5,
      "logo-priority-print",
      "FAST"
    );
  }

  const vendorName = vendorPickupDetail(
    params.data.sttVendorName,
    params.data.sttDistrictType
  );

  if (vendorName) {
    const vendorRightMargin = params.data.isPriorityTier ? 15 : 9;
    createRoundRect(
      params.doc,
      vendorName,
      rightEndX - vendorRightMargin,
      barcodePositionY
    );
  }

  // Bottom line - static position

  const endContentY = params.startY + 92;
  params.doc.setLineDash([], 0);
  params.doc.setLineWidth(0.4);
  params.doc.setDrawColor("#000000");
  params.doc.line(rightEndX, senderPositionY - 5, rightEndX, endContentY);
  params.doc.line(params.startX + 0, endContentY, params.startX + 105, endContentY);

  return {
    ...params,
    startX: rightEndX,
    startY: endContentY
  };
};