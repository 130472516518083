import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import startCase from "lodash/startCase";
import moment from "moment";
import { limitLengthName } from "../../module";

export const RecipientDestinationSection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  estimateSlaParam: string,
  translate: Function,
  language: string
) => {
  const leftMargin = params.startX + 30;

  const sttDestinationDistrictNameString =
    params.data.sttDestinationDistrictName.length > 40
      ? `${params.data.sttDestinationDistrictName.substring(0, 40)}...`
      : params.data.sttDestinationDistrictName;

  const districtString = params.doc.splitTextToSize(
    sttDestinationDistrictNameString,
    22
  );

  const shipperPositionY =
    params.startY + (districtString.length > 2 ? 128 : 129);
  const breakdownPositionY = shipperPositionY + 2.5;
  const breakdownPositionYL2 = breakdownPositionY + 2.5;

  params.doc.setFontSize(5);
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.shipper")),
    leftMargin + 2,
    shipperPositionY
  );
  params.doc.text(
    startCase(translate("receipt.receiver")),
    leftMargin + 2,
    breakdownPositionY
  );
  params.doc.text(
    startCase(translate("receipt.destination")),
    leftMargin + 2,
    breakdownPositionYL2
  );

  const sttSenderNameData = limitLengthName(params.data.sttSenderName, 20);
  const sttRecipient = limitLengthName(params.data.sttRecipientName, 20);

  params.doc.setFont("Montserrat", "semibold");
  params.doc.text(":", leftMargin + 14, shipperPositionY);
  params.doc.text(":", leftMargin + 14, breakdownPositionY);
  params.doc.text(":", leftMargin + 14, breakdownPositionYL2);
  params.doc.text(sttSenderNameData, leftMargin + 15, shipperPositionY);
  params.doc.text(sttRecipient, leftMargin + 15, breakdownPositionY);
  params.doc.text(districtString, leftMargin + 15, breakdownPositionYL2);

  const districtHeight = params.doc.getTextDimensions(districtString).h;
  const breakdownPositionYL3 = breakdownPositionYL2 + districtHeight + 0.6;
  const breakdownPositionYL4 = breakdownPositionYL3 + 2.5;

  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.createdAtV2")),
    leftMargin + 2,
    breakdownPositionYL3
  );
  params.doc.text(
    startCase(translate("receipt.estimation")),
    leftMargin + 2,
    breakdownPositionYL4
  );

  const newSttCreatedAtDate = new Date(params.data.sttCreatedAt);
  const sttCreatedAt = new Intl.DateTimeFormat("en-GB").format(
    newSttCreatedAtDate
  );

  params.doc.setFont("Montserrat", "semibold");
  params.doc.text(":", leftMargin + 14, breakdownPositionYL3);
  params.doc.text(":", leftMargin + 14, breakdownPositionYL4);
  params.doc.text(sttCreatedAt, leftMargin + 15, breakdownPositionYL3);

  const estimateSla = moment(estimateSlaParam, "DD MMM YYYY").format(
    "DD/MM/YYYY"
  );

  params.doc.text(estimateSla, leftMargin + 15, breakdownPositionYL4);
};
