import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const getWaterMark = async (params: {
  doc: jsPDF;
  bookingType: string;
  accountType: string;
  data: PrintDataEntitas;
  pieceData: any;
  barcode: string;
  vendorRefCode: string;
  barcodeValue: string;
  vendorRefCodeValue: string;
  logo: string;
  shipmentId: string;
  startX: number;
  startY: number;
}) => {
  const isSpecialProduct =
    params.data.sttProductType.toUpperCase() === "BOSSPACK" ||
    params.data.sttProductType.toUpperCase() === "SAMEDAY" ||
    params.data.sttProductType.toUpperCase() === "VIPPACK";
  let result = "";
  const prefixProduct = params.data.sttProductType.toLowerCase();
  if (isSpecialProduct) {
    if (params.data.sttIsCod && !params.data.sttIsDfod) {
      result = params.data.isDangerousGoods
        ? `${prefixProduct}_cod_dg`
        : `${prefixProduct}_cod`;
    } else if (params.data.sttIsDfod) {
      result = params.data.isDangerousGoods
        ? `${prefixProduct}_dfod_dg`
        : `${prefixProduct}_dfod`;
    } else if (params.data.isDangerousGoods) {
      result = `${prefixProduct}_dg`;
    } else {
      result = prefixProduct;
    }
  } else {
    if (params.data.sttIsCod && !params.data.sttIsDfod) {
      result = params.data.isDangerousGoods ? "cod_dg" : "cod";
    } else if (params.data.sttIsDfod) {
      result = params.data.isDangerousGoods ? "dfod_dg" : "dfod";
    } else if (params.data.isDangerousGoods) {
      result = "dg";
    }
  }

  if (params.data.isPriorityTier) {
    result = result == "" ? "prioritas" : `prioritas_${result}`;
  }

  if (result) {
    const watermark = await import(
      `@/app/ui/assets/images/watermark-v2/${result}.png`
    );
    return params.doc.addImage(
      watermark.default,
      "PNG",
      params.startX,
      params.startY,
      104,
      144,
      `${result}`,
      "FAST"
    );
  }
};
